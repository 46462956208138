document.addEventListener(
  "click",
  function(event) {
    if (event.target.classList.contains("menu-toggle")) {
      var body = document.querySelector("body");
      body.classList.toggle("menu-is-open");
      var hamburger = document.querySelector(".hamburger");
      hamburger.classList.toggle("is-active");
    }
  },
  false
);
function throttle(func, wait) {
  let waiting = false;
  return function() {
    if (waiting) {
      return;
    }

    waiting = true;
    setTimeout(() => {
      func.apply(this, arguments);
      waiting = false;
    }, wait);
  };
}

let scrollPosition = window.scrollY;
function runOnScroll() {
  scrollPosition = window.scrollY;
  if (scrollPosition >= 20) {
    document.body.classList.add("scrolled");
  } else {
    document.body.classList.remove("scrolled");
  }
}

window.addEventListener("scroll", throttle(runOnScroll, 400), { passive: true });
